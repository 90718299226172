/* Main components */
h1, h2, h3, h4, h5, h6, p{
  margin: 0;
  padding: 0;
}
a {
  font-size: 1rem;
  text-decoration: none;
}

/* Color Palette */

.mainBackground {
  color: rgba(91,0,166,1);
}
.mainAction {
  color: rgb(0, 255, 236);
}
.mainActionBackground{
  background-color: #00FFEC;
}
.secondaryAction {
  color: #FFF2D4;
}
.border{
  border: 1px solid rgb(246, 198, 234);
  border: 2px solid #ffc3a0;
}
.mainText{
  color: white;
}
.secondaryTextColor{
  color: rgba(255, 255, 255, 0.801);
}

/* Classes */
.center {
  text-align: center;
}

.flexColumn {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  gap: 3rem;
}

.flexRow {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
}
.width60{
  width: 60%;
}
.width90{
  width: 90%;
}
.gap20{
  gap: 20px;
}

.firstSection {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(48,213,200,0.5));
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.picture{
  position:absolute;
  z-index: -1;
  width: 100%;
  border-radius: 25%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}


.item1{
  grid-area: item1;
}
.item2{
  grid-area: item2;
}
.item3{
  grid-area: item3;
}
.item4{
  grid-area: item4;
}

.showGrid{
  display: grid;
  width: 95%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
  "item1 item2"
  "item3 item4";
  place-items: center;
  row-gap: 20px;
}

.mainButton{
  height: auto;
  width: 200px;
  padding: 0.5rem 1rem;
  margin: 10px 0px;
  background-color: turquoise;
  border: 1px solid rgb(246, 198, 234);
  color: black;
  border-radius: 10px;
  box-shadow: 1px -2px 5px 1px rgba(246, 198, 234, 0.5);
  z-index: 60;
  cursor: pointer;
}
.secondaryButton{
  height: auto;
  width: 200px;
  padding: 0.5rem 1rem;
  margin: 10px 0px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border: 2px solid turquoise;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 1px rgba(246, 198, 234, 0.5);
  z-index: 60;
  cursor: pointer;
}
.picChangeButton{
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 10px 0px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px) saturate(150%);
  border: 1px solid rgba(255, 255, 255, 0.404);
  box-shadow: 1px 2px 5px 1px rgba(76, 76, 76, 0.5);
  z-index: 60;
  cursor: pointer;
}
.navButton{
  height: auto;
  width: 100px;
  padding: 0.5rem 0.5rem;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(20px) saturate(150%);
    -webkit-backdrop-filter: blur(20px);
  border: 1px solid #FFF2D4;
  border-radius: 25px;
  box-shadow: 1px 2px 5px 1px rgba(246, 198, 234, 0.5);
  z-index: 60;
  cursor: pointer;
}
.emoji{
  font-size: 3rem;
}

/* Picture show */
.pictureShow{
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(48,213,200,0.5));
}
.picChangeButtonAbsolute{
  position:absolute;
  bottom: 1rem;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px) saturate(150%);
  border: 1px solid rgba(255, 255, 255, 0.404);
  box-shadow: 1px 2px 5px 1px rgba(76, 76, 76, 0.5);
  z-index: 60;
  cursor: pointer;
  left: 50%;
}
.menuChangeAbsolute{
  position:absolute;
  bottom: 1rem;
  right: 1rem;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px) saturate(150%);
  border: 1px solid rgba(255, 255, 255, 0.404);
  box-shadow: 1px 2px 5px 1px rgba(76, 76, 76, 0.5);
  z-index: 60;
  cursor: pointer;
}
